import React from "react";
import { message } from "antd";
import { Loader } from "@googlemaps/js-api-loader";
import { getUser, updateUser } from "./User";
import "./SettingsPage.css";
import "./AddressView.css";

class AddressView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      address: {
        recipient: "",
        street_address: "",
        street_address2: "",
        locality: "",
        region: "",
        postal_code: "",
        country: "",
      },
      autocomplete: null,
      editAddress: false,
    };

    this.initAutocomplete = this.initAutocomplete.bind(this);
    this.fillInAddress = this.fillInAddress.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.submitAddress = this.submitAddress.bind(this);

    this.address1ref = React.createRef();
    this.address2ref = React.createRef();
    this.postcoderef = React.createRef();
  }

  componentDidMount() {
    getUser().then((user) => {
      this.setState({ user });
      try {
        // In order to adapt default mock data
        const defaultAddress = ["100 Main Street, Anytown, USA", "None"]
        if(user?.attributes?.address && !defaultAddress.includes(user?.attributes?.address)) {
          const address = JSON.parse(user.attributes.address);
          this.setState({ address });
        }
      } catch (e) {}
    });
  }

  initAutocomplete() {
    this.address1ref.current.value = this.state.address.street_address;
    const loader = new Loader({
      apiKey: "AIzaSyAYOIqd0yEIRZcIlyzeCmm9ldx4amzu4F8",
      version: "weekly",
      libraries: ["places"],
    });
    loader
      .load()
      .then((google) => {
        const autocomplete = new google.maps.places.Autocomplete(
          this.address1ref.current,
          {
            componentRestrictions: { country: ["us", "ca"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
          }
        );
        this.setState({ autocomplete });
        autocomplete.addListener("place_changed", this.fillInAddress);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  fillInAddress() {
    const place = this.state.autocomplete.getPlace();
    let address1 = "";
    const address = { ...this.state.address, postal_code: "" };
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          address.street_address = address1;
          break;
        }
        case "route": {
          address1 += component.short_name;
          address.street_address = address1;
          break;
        }
        case "postal_code": {
          if (address.postal_code.includes("-")) {
            address.postal_code = `${component.long_name}${address.postal_code}`;
          } else {
            address.postal_code = component.long_name;
          }
          break;
        }
        case "postal_code_suffix": {
          address.postal_code = `${address.postal_code}-${component.long_name}`;
          break;
        }
        case "locality": {
          address.locality = component.long_name;
          break;
        }
        case "administrative_area_level_1": {
          address.region = component.short_name;
          break;
        }
        case "country": {
          address.country = component.long_name;
          break;
        }
        default: {
          break;
        }
      }
    }
    this.setState({ address });
    this.address1ref.current.value = address1;
  }

  updateAddress = (field) => (evt) => {
    console.log(evt.target.value);
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        [field]: evt.target.value,
      },
    }));
  };

  submitAddress() {
    const address = { ...this.state.address };
    updateUser({ address: JSON.stringify(address) });
    this.setState({ editAddress: false });
  }

  render() {
    const { user, billing, defaultSource } = this.state;
    return (
      <div className="address-container" style={{ width: "400px" }}>
        <p className="shipping-address-title">Shipping Address</p>
        {this.state.editAddress ? (
          <form
            id="address-form"
            onSubmit={this.submitAddress}
            action=""
            autoComplete="off"
          >
            {/* <p className="note"><em>* = required field</em></p> */}
            <input
              className="form-field"
              required
              placeholder="Shipping recipient name"
              value={this.state.address.recipient}
              onChange={this.updateAddress("recipient")}
            />
            <label className="full-field">
              {/* <span className="form-label">Deliver to*</span> */}
              <input
                className="form-field"
                id="ship-address"
                name="ship-address"
                required
                autoComplete="off"
                ref={this.address1ref}
                placeholder="Enter Address here"
                value={this.state.address.street_address}
                onChange={this.updateAddress("street_address")}
              />
            </label>
            {/* {this.state.address.street_address ? */}
            <>
              <input
                placeholder="Apt, suite, unit, building, floor, etc."
                className="form-field"
                id="address2"
                name="address2"
                value={this.state.address.street_address2}
                onChange={this.updateAddress("street_address2")}
                // onChange={(e) => this.setState({ address2: e.target.value })}
              />
              <input
                placeholder="City"
                className="form-field"
                id="locality"
                name="locality"
                value={this.state.address.locality}
                onChange={this.updateAddress("locality")}
                required
              />
              <input
                placeholder="State/Province"
                className="form-field"
                id="state"
                name="state"
                value={this.state.address.region}
                onChange={this.updateAddress("region")}
                required
              />
              <input
                placeholder="Postal code"
                className="form-field"
                id="postcode"
                name="postcode"
                value={this.state.address.postal_code}
                onChange={this.updateAddress("postal_code")}
                required
              />
              <input
                placeholder="Country/Region"
                className="form-field"
                id="country"
                name="country"
                value={this.state.address.country}
                onChange={this.updateAddress("country")}
                required
              />
              <input
                type="submit"
                form="address-form"
                className="change-address-button"
                value="Save address"
              />
              {/* <button className="change-password-submit-button" onClick={}>Clear address</button> */}
              {/* <button type="button" className="my-button">Save address</button>

                                    <input className='form-field' type="reset" value="Clear form" /> */}
            </>
            {/* : null} */}
          </form>
        ) : (
          <>
            <p className="address-text">{this.state.address.recipient}</p>
            <p className="address-text">{this.state.address.street_address}</p>
            <p className="address-text">{this.state.address.street_address2}</p>
            <div className="address-text">
              <span className="address-text">
                {this.state.address.locality}{this.state.address.locality && ","}{" "}
              </span>
              <span className="address-text">{this.state.address.region} </span>
              <span className="address-text">
                {this.state.address.postal_code}
              </span>
            </div>
            <p className="address-text">{this.state.address.country}</p>
            <button
              className="change-address-button"
              onClick={() => {
                this.setState({ editAddress: true }, () => {
                  this.initAutocomplete();
                });
              }}
            >
              Edit address
            </button>
          </>
        )}
      </div>
    );
  }
}

export const AddressViewSimple = ({ address }) => {
  try {
    address = JSON.parse(address);
    return (
      <>
        <p className="address-line">{address.recipient}</p>
        <p className="address-line">{address.street_address}</p>
        <p className="address-line">{address.street_address2}</p>
        <span className="address-line">{address.locality}, </span>
        <span className="address-line">{address.region}</span>
        <p className="address-line">{address.postal_code}</p>
        <p className="address-line">{address.country}</p>
      </>
    );
  } catch (err) {
    return (
      <>
        <p>-</p>
      </>
    );
  }
};

export const ValidateAddress = async () => {
  getUser().then((user) => {
    this.setState({ user });
    try {
      const address = JSON.parse(user.attributes.address);
      return true;
    } catch (e) {
      return false;
    }
  });
};

export default AddressView;
